$(document).on('mousemove', '.guild-resizeable-width', (event) => {
    const resizeable = $(event.currentTarget);
    const resizeableWidth = resizeable[0].getBoundingClientRect().width;

    if (event.offsetX < 5) {
        resizeable.addClass('guild-potentially-resizing-width');
    } else {
        resizeable.removeClass('guild-potentially-resizing-width');
    }
});


$(document).on('mousemove', (event) => {
    const resizing = $('.guild-is-resizing-width');

    if (resizing.length > 0) {
        const delta = event.pageX - resizing[0].getBoundingClientRect().x;
        resizing[0].style.width = (resizing[0].getBoundingClientRect().width - delta) + 'px';
        event.preventDefault();
    }
});


$(document).on('mousedown', '.guild-resizeable-width', (event) => {
    if ($(event.currentTarget).hasClass("guild-potentially-resizing-width")) {
        $(event.currentTarget).addClass('guild-is-resizing-width');
    }
});

$(document).on('mouseup', (event) => {
    $('.guild-is-resizing-width').each((index, element) => {
        $(element).removeClass('guild-is-resizing-width');
    });
});


